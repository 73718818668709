import React from "react"
import Layout from "../components/Layout/Layout"
import Welcome from "../components/Sections/Welcome";
import Services from "../components/Sections/Services";

export default function Kontakt() {
    return (
        <Layout>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
<h1 className={"mt-5"}>Kontakt</h1>
        </Layout>
    );
}